// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// AMBIENTE DEV desarrollo
// ng serve --configuration=dev
// ng build --configuration=dev
export const environment = {
  production: false,
  // ambiente
  Ambiente: 'DevFactorajeAU',
  // para mi fiel /////
  APP_ID: '',
  APP_SECRET: '',
  URL_MIFIEL: '',
  // para mi fiel /////
  URL_SERVICIOS: 'https://fgdev.devfactorgfc.com/api/v1',
 // SECRET_KEY: '71e141d3a016ffd6bd94558a5bb80b15',
  SECRET_KEY: 'a487efef14bed450a9709ca41f2619cb',
  CLIENTE: 'FACTORGFCGLOBAL',
  firebase: {
    
    apiKey: "AIzaSyCQCyYhc1fGClzSrz6mJOV1pfLUwvJ2bDg",
    authDomain: "devfgau-42916.firebaseapp.com",
    projectId: "devfgau-42916",
    storageBucket: "devfgau-42916.appspot.com",
    messagingSenderId: "645589778110",
    appId: "1:645589778110:web:44d14c2175330bc1e1e8ef",
    measurementId: "G-0JQTP05L30"
    

  },
  AWS: {
    accessKeyId: "AKIAWRCSQFXTXKQ57W5G",
    secretAccessKey: "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",
    region: "us-east-1",
    folder: "factoraje_contributor_documents/DevFGAU/",
    bucketNomina: "elasticbeanstalk-us-east-1-448997109223",
  },

};
